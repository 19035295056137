export interface Aircraft {
    readonly registration: string,
    readonly maxTow: Kg,
    readonly maxFuel: Liter,
    readonly maxBaggage: Kg,
    readonly bew: Kg,
    readonly bewArm: MomentArm,
    readonly frontSeatArm: MomentArm,
    readonly backSeatArm?: MomentArm,
    readonly fuelArm: MomentArm,
    readonly baggageArm: MomentArm,
    readonly cogLimits: CoGLimitsPolygon,
    readonly cogLimitsUtility?: CoGLimitsPolygon,
    readonly info: AircraftInfo[],
}

export type Kg = number
type MomentArm = Meter
export type Meter = number
type CoGLimitsPolygon = number[][] /* [ [Kg, MomentArm in meter] ] */
export type Liter = number

export interface Row {
    weight: Kg,
    arm: Meter,
    moment: number,
}

export const emptyRow = { weight: 0, arm: 0, moment: 0 }

export interface AircraftInfo {
    title: string;
    value: string;
}
