/**
 * Y is KG
 * X is MomentArm in meter
 */
import React from 'react';
import {Aircraft, Row} from './Types';

const svgwidht = 400;
const svgheight = 400;
const svgmargin = 10;

/**
 * X is arm and Y is kg.
 */
interface Ykxm {
    kx: number;
    mx: number;
    ky: number;
    my: number;
}

interface CoGGraphProps {
    ac: Aircraft,
    bew: Row,
    zfw: Row,
    tow: Row,
    lw: Row,
}

export const RenderCoGGraph = (props: CoGGraphProps) => {
    const p = props;
    const cogLimits = p.ac.cogLimits;
    const minXY = cogLimits.reduce( (acc, curr) => {
        return [Math.min(acc[0], curr[0]), Math.min(acc[1], curr[1])];
    }, cogLimits[0]);
    const maxXY = cogLimits.reduce( (acc, curr) => {
        return [Math.max(acc[0], curr[0]), Math.max(acc[1], curr[1])];
    }, cogLimits[0]);
    // y = kx + m
    // k = (y1-y0)/(x1-x0)
    // m = y1 - k*x1
    // Note that X is arm (index 0) and Y is kg (index 1).
    const ky = (0-svgheight) / (maxXY[0] - minXY[0]);
    const my = 0 - (ky * maxXY[0]);
    const kx = (svgwidht-0) / (maxXY[1] - minXY[1]);
    const mx = svgwidht - (kx * maxXY[1]);
    const ykxm: Ykxm = {
        kx,
        mx,
        ky,
        my,
    };
    const coglimitsPoints = cogLimits.map( (x) => {return point2SvgString(ykxm, x)}).toString().replace(/,/g, " ");
    const coglimitsUtilityPoints = p.ac.cogLimitsUtility?.map( (x) => {return point2SvgString(ykxm, x)}).toString().replace(/,/g, " ");
    const coglimitsUtilityMaxXY = p.ac.cogLimitsUtility?.reduce( (acc, curr) => {
        return curr[0] > acc[0] ? curr : acc;
    }, [0, 0]);
    const coglimitsUtilityMaxXYPoints = coglimitsUtilityMaxXY !== undefined ? point2Svg(ykxm, coglimitsUtilityMaxXY) : [0, 0];
    const svgViewBox = `${-svgmargin} ${-svgmargin} ${svgwidht+svgmargin*2} ${svgheight+svgmargin*2}`
    const bew = {x: arm2x(ykxm, p.bew.arm), y: kg2y(ykxm, p.bew.weight)};
    const tow = {x: arm2x(ykxm, p.tow.arm), y: kg2y(ykxm, p.tow.weight)};
    const zfw = {x: arm2x(ykxm, p.zfw.arm), y: kg2y(ykxm, p.zfw.weight)};
    const lw = {x: arm2x(ykxm, p.lw.arm), y: kg2y(ykxm, p.lw.weight)};
    const lines = [];
    for (let i = Math.floor(minXY[0]/100); i <= Math.floor(maxXY[0]/100) + 1; i++) {
        lines.push(i*100);
    }
    return ( <svg width={svgwidht+svgmargin*2} height={svgheight+svgmargin*2} viewBox={svgViewBox}>
            {lines.map( (l) => {
                return (<g key={l}>
                    <line x1={-svgmargin} y1={kg2y(ykxm, l)}
                          x2={svgwidht+svgmargin} y2={kg2y(ykxm, l)}
                          stroke="black" strokeWidth="1"/>
                    <text x={10} y={kg2y(ykxm, l)-2}>{l}</text>
                </g>);
            })};
            { coglimitsUtilityPoints !== undefined &&
                <polygon points={coglimitsUtilityPoints}
                    stroke="orange" fill="transparent" strokeWidth="2"/> }
            { coglimitsUtilityPoints !== undefined &&
                <text x={coglimitsUtilityMaxXYPoints[0]+5}
                      y={coglimitsUtilityMaxXYPoints[1]+15}
                      fill="orange" >Utility category</text>
            }
            <polygon points={coglimitsPoints}
                stroke="red" fill="transparent" strokeWidth="5"/>
            <line x1={zfw.x} y1={zfw.y}
                  x2={tow.x} y2={tow.y}
                  stroke="black" strokeWidth="3"/>
            <line x1={bew.x} y1={bew.y}
                  x2={zfw.x} y2={zfw.y}
                  stroke="black" strokeWidth="3"/>
            <circle cx={bew.x} cy={bew.y} r="5" fill="black"/>
            <circle cx={lw.x} cy={lw.y} r="5" fill="yellow"/>
            <circle cx={tow.x} cy={tow.y} r="5" fill="blue"/>
            <circle cx={zfw.x} cy={zfw.y} r="5" fill="green"/>
            { p.bew.weight !== p.zfw.weight && <text x={bew.x+5} y={bew.y+5}>BEW</text> }
            <text x={zfw.x-40} y={zfw.y+5}>ZFW</text>
            { p.tow.weight !== p.zfw.weight && <text x={tow.x+5} y={tow.y+5}>TOW</text> }
            { p.lw.weight !== p.tow.weight && <text x={lw.x-30} y={lw.y+5}>LW</text> }
        </svg>
    );
}
/**
 * Index 0 is kg
 * Index 1 is arm
 */
const point2Svg = (ykxm: Ykxm, p: number[]): number[] => {
    if (p.length === 2) {
        const svgX = arm2x(ykxm, p[1]);
        const svgY = kg2y(ykxm, p[0]);
        return [svgX, svgY];
    } else {
        throw new Error(`Wrong length for a point: ${p}`);
    }
}
const point2SvgString = (ykxm: Ykxm, p: number[]): string => {
    const svgXY = point2Svg(ykxm, p);
    return `${svgXY[0]} ${svgXY[1]}`;
}
const arm2x = (ykxm: Ykxm, arm: number): number => {
    return ykxm.kx*arm + ykxm.mx;
}
const kg2y = (ykxm: Ykxm, kg: number): number => {
    return ykxm.ky*kg + ykxm.my;
}

