import React from 'react';
import './MassAndBalance.css';
import {Aircraft, Row, emptyRow} from './Types';
import {liter2kg} from './Conversion';
import * as CoGGraph from './CoGGraph';

export interface MassAndBalanceProps {
    aircraft: Aircraft | undefined,
}

interface MassAndBalanceState {
    bew: Row,
    frontseat: Row,
    backseat: Row,
    baggage: Row,
    zfw: Row,
    fuel: Row,
    tow: Row,
    tripfuel: Row,
    lw: Row,
}


class MassAndBalance extends React.Component<MassAndBalanceProps> {
  state: MassAndBalanceState = {
      bew: { ...emptyRow },
      frontseat: { ...emptyRow },
      backseat: { ...emptyRow },
      baggage: { ...emptyRow },
      zfw: { ...emptyRow },
      fuel: { ...emptyRow },
      tow: { ...emptyRow },
      tripfuel: { ...emptyRow },
      lw: { ...emptyRow },
  };
  updateCalculations() {
      const s = this.state;
      const bewMoment = this.calculateMoment(s.bew);
      const frontseatMoment = this.calculateMoment(s.frontseat);
      const backseatMoment = this.calculateMoment(s.backseat);
      const baggageMoment = this.calculateMoment(s.baggage);
      const zfw = [bewMoment, frontseatMoment, backseatMoment, baggageMoment].reduce( (prev: Row, curr: Row) => {
          return {...prev, weight: prev.weight + curr.weight, moment: prev.moment + curr.moment}
      }, emptyRow);
      const zfwArm = this.calculateArm(zfw);
      const fuelKg = {...s.fuel, weight: liter2kg(s.fuel.weight)};
      const fuelMoment = this.calculateMoment(fuelKg);
      const tow = [zfwArm, fuelMoment].reduce( (prev: Row, curr: Row) => {
          return {...prev, weight: prev.weight + curr.weight, moment: prev.moment + curr.moment}
      }, emptyRow);
      const towArm = this.calculateArm(tow);

      const tripfuelKg = {...s.tripfuel, weight: liter2kg(s.tripfuel.weight)}
      const tripfuelMoment = this.calculateMoment(tripfuelKg)
      const lw = [tripfuelMoment].reduce( (prev: Row, curr: Row) => {
          return {...prev, weight: prev.weight - curr.weight, moment: prev.moment - curr.moment}
      }, towArm);
      const lwArm = this.calculateArm(lw);

      // Restore weight to liter for rendering.
      const fuelLiter = {...fuelMoment, weight: s.fuel.weight};
      const tripfuelLiter = {...tripfuelMoment, weight: s.tripfuel.weight};
      this.setState(
        {
            bew: bewMoment,
            frontseat: frontseatMoment,
            backseat: backseatMoment,
            baggage: baggageMoment,
            zfw: zfwArm,
            fuel: fuelLiter,
            tow: towArm,
            tripfuel: tripfuelLiter,
            lw: lwArm,
        }
      );
  }
  calculateMoment(row: Row): Row {
      row.moment = row.weight*row.arm;
      return row;
  }
  calculateArm(row: Row): Row {
      row.arm = row.moment / row.weight;
      return row;
  }
  componentDidMount() {
      if (this.props.aircraft !== undefined) {
          const ac = this.props.aircraft;
          const initState = {
              bew: {...emptyRow, weight: ac.bew, arm: ac.bewArm },
              frontseat: {...emptyRow, arm: ac.frontSeatArm },
              backseat: {...emptyRow, arm: ac.backSeatArm ?? emptyRow.arm },
              baggage: {...emptyRow, arm: ac.baggageArm },
              zfw: {...emptyRow },
              fuel: {...emptyRow, arm: ac.fuelArm },
              tow: {...emptyRow },
              tripfuel: {...emptyRow, arm: ac.fuelArm },
              lw: {...emptyRow},
          }
          this.setState(initState);
          setTimeout(() => this.updateCalculations(), 0);
      }
  }
  weightOnChange = (key: keyof MassAndBalanceState) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const row = this.state[key];
      row.weight = Number(e.target.value);
      this.setState({row});
      setTimeout(() => this.updateCalculations(), 0);
  }
  render() {
      if (this.props.aircraft === undefined) {
          return (
            <div className="MassAndBalance">
                Select an aircraft!
            </div>
          )
      } else {
          const ac = this.props.aircraft;
          return (
            <div className="MassAndBalance Flexbox">
            <div>
                <div className="table">
                    <div className="table-row">
                        <div className="table-cell"></div>
                        <div className="table-cell">Weight</div>
                        <div className="table-cell"></div>
                        <div className="table-cell">Arm (m)</div>
                        <div className="table-cell">Moment (kg-m)</div>
                    </div>
                    <RenderRow title="+BEW" row={this.state.bew} />
                    <RenderRow title="+Frontseat" row={this.state.frontseat} onChange={this.weightOnChange("frontseat")}/>
                    { ac.backSeatArm && <RenderRow title="+Backseat" row={this.state.backseat} onChange={this.weightOnChange("backseat")}/> }
                    <RenderRow title="+Baggage" row={this.state.baggage} onChange={this.weightOnChange("baggage")} aboveLimits={this.state.baggage.weight > ac.maxBaggage}/>
                    <RenderRow title="=ZFW" row={this.state.zfw} outsideLimits={!checkCoGLimits(this.props.aircraft, this.state.zfw)}/>
                    <RenderRow title="+Fuel" row={this.state.fuel} onChange={this.weightOnChange("fuel")} unit="liter" aboveLimits={this.state.fuel.weight > ac.maxFuel}/>
                    <RenderRow title="=TOW" row={this.state.tow} aboveLimits={this.state.tow.weight > ac.maxTow} outsideLimits={!checkCoGLimits(this.props.aircraft, this.state.tow)}/>
                    <RenderRow title="-Trip fuel" row={this.state.tripfuel} onChange={this.weightOnChange("tripfuel")} unit="liter" aboveLimits={this.state.tripfuel.weight > this.state.fuel.weight}/>
                    <RenderRow title="=LW" row={this.state.lw} outsideLimits={!checkCoGLimits(this.props.aircraft, this.state.lw)}/>
                </div>
                <hr/>
                <div className="InfoGrid">
                        <div>MTOW:</div><div>{Math.floor(ac.maxTow)} kg</div>
                        <div>Max fuel:</div><div>{ac.maxFuel} liters</div>
                        <div>Max baggage:</div><div>{ac.maxBaggage?.toFixed(2)} kg</div>
                        {ac.info.map((i) => { return (
                            <React.Fragment key={i.title+i.value}><div>{i.title}:</div><div>{i.value}</div></React.Fragment>
                        )})}
                </div>
            </div>
            <CoGGraph.RenderCoGGraph ac={ac} bew={this.state.bew} zfw={this.state.zfw} tow={this.state.tow} lw={this.state.lw}/>
            </div>
          )
      }
  }
}

export default MassAndBalance;

interface RenderRowProps {
    title: string,
    row: Row,
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
    unit?: string,
    aboveLimits?: boolean,
    outsideLimits?: boolean,
}

const RenderRow = (props:RenderRowProps): JSX.Element => {
    let readonly = true;
    let onChange = undefined;
    if (props.onChange !== undefined) {
        readonly = false;
        onChange = props.onChange;
    }
    const unit = props.unit !== undefined ? props.unit : "kg";
    const aboveLimits = props.aboveLimits ? "aboveLimits" : "";
    const outsideLimits = props.outsideLimits ? "outsideLimits" : "";
    return (
            <div id={`${props.title}-row`} className="table-row">
            <div id={`${props.title}-title`} className="table-cell">{props.title}</div>
            <div id={`${props.title}-inputdiv`} className={"table-cell " + aboveLimits}>
            <input id={`${props.title}-input`} type="number" min="0" value={props.row.weight.toString()} onChange={onChange} readOnly={readonly}/>
            </div>
            <div id={`${props.title}-unit`} className="table-cell">{unit}</div>
            <div id={`${props.title}-arm`} className={"table-cell " + outsideLimits}>{props.row.arm.toFixed(3)}</div>
            <div id={`${props.title}-moment`} className="table-cell">{props.row.moment.toFixed(3)}</div>
            </div>
           )
  }


/**
 * Even–odd rule to check if a point is within a polygon.
 * https://en.wikipedia.org/wiki/Even%E2%80%93odd_rule
 *
 * Returns true if inside the polygon.
 */
export const checkCoGLimits = (aircraft: Aircraft, row: Row):boolean => {
    const poly = aircraft.cogLimits;
    if (poly.length <= 2) {
        // Too few points in polygon.
        return false;
    }
    let j = poly.length - 1;
    let c = false;
    for (let i = 0; i < poly.length; i++) {
        const polyKgi = poly[i][0];
        const polyArmi = poly[i][1];
        const polyKgj = poly[j][0];
        const polyArmj = poly[j][1];
        if (row.arm === polyArmi && row.weight === polyKgi) {
            // Point is a corner.
            return true;
        }
        if ((polyKgi > row.weight) !== (polyKgj > row.weight)) {
            const slope = (row.arm - polyArmi) * (polyKgj-polyKgi) - (polyArmj-polyArmi) * (row.weight - polyKgi);
            if (slope === 0) {
                // Point is on border.
                return true;
            }
            if ((slope < 0) !== (polyKgj < polyKgi)) {
                c = !c;
            }
        }
        j = i;
    }
    return c;
}
