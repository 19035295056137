import React from 'react';
import './AircraftSelector.css';
import MassAndBalance from './MassAndBalance';
import {Aircraft} from './Types';
import * as Aircrafts from './Aircrafts';

interface State {
    aircrafts: Aircraft[],
    selectedAircraft: string,
}

class AircraftSelector extends React.Component {
    state: State = {
        aircrafts: Aircrafts.aircrafts.sort((a, b) => {return a.registration.localeCompare(b.registration)}),
        selectedAircraft: Aircrafts.aircrafts[0]?.registration,
    }

  aircraftSelector = (e: React.ChangeEvent<HTMLSelectElement>) => {
      this.setState({ selectedAircraft: e.target.value});
  };

  render(): JSX.Element {
      const theAircraft = this.state.aircrafts.find( (x) => x.registration === this.state.selectedAircraft);
      return (
        <div className="AircraftSelector">
        <div className="Banner">
            <span className="BannerText">ALWAYS CONSOLIDATE THE PILOT OPERATING HANDBOOK FOR CORRECT VALUES</span>
        </div>
        <span>Aircraft: </span>
        <select name="Aircraft" id="Aircrafts" onChange={this.aircraftSelector}>
          {this.state.aircrafts.map( (ac) => {
              return <option value={ac.registration}>{ac.registration}</option>
          })}
        </select>
        <MassAndBalance aircraft={theAircraft} key={theAircraft?.registration} /> {/* key is needed to create a new instance when A/C is changed */}
        </div>
      )
  }
}

export default AircraftSelector;
