import {Aircraft} from './Types';
import {lbs2kg, inches2m, usgal2liter} from './Conversion';

export const aircrafts: Aircraft[] = [
    {
        registration: "SE-IUD",
        maxTow: 1155,
        maxFuel: 182,
        maxBaggage: lbs2kg(200),
        bew: 734.2,
        bewArm: 2.219,
        frontSeatArm: inches2m(80.5),
        backSeatArm: inches2m(118.1),
        fuelArm: inches2m(95.0),
        baggageArm: inches2m(142.8),
        cogLimits: [ [lbs2kg(1200), inches2m(82)],
                     [lbs2kg(2050), inches2m(82)],
                     [lbs2kg(2550), inches2m(88.5)],
                     [lbs2kg(2550), inches2m(93)],
                     [lbs2kg(1200), inches2m(93)] ],
        cogLimitsUtility: [ [lbs2kg(1200), inches2m(82)],
                            [lbs2kg(2050), inches2m(82)],
                            [lbs2kg(2100), inches2m(82.7)],
                            [lbs2kg(2100), inches2m(93)],
                            [lbs2kg(1200), inches2m(93)] ],
        info: [
            {title: "Max crosswind", value: "17 kt"},
            {title: "Vs", value: "55 kt"},
            {title: "Vso", value: "49 kt"},
            {title: "Vx", value: "64 kt"},
            {title: "Vy", value: "76 kt"},
            {title: "Final appr speed", value: "66 kt"},
            {title: "Fuel", value: "91/96 UL or 100LL"},
            {title: "Fuelflow", value: "40 liters/h"},
        ],
    },
    {
        registration: "SE-KIT",
        maxTow: 1155,
        maxFuel: 182,
        maxBaggage: lbs2kg(200),
        bew: 734.2,
        bewArm: 2.219,
        frontSeatArm: inches2m(80.5),
        backSeatArm: inches2m(118.1),
        fuelArm: inches2m(95.0),
        baggageArm: inches2m(142.8),
        cogLimits: [ [lbs2kg(1200), inches2m(82)],
                     [lbs2kg(2050), inches2m(82)],
                     [lbs2kg(2550), inches2m(88.5)],
                     [lbs2kg(2550), inches2m(93)],
                     [lbs2kg(1200), inches2m(93)] ],
        cogLimitsUtility: [ [lbs2kg(1200), inches2m(82)],
                            [lbs2kg(2050), inches2m(82)],
                            [lbs2kg(2100), inches2m(82.7)],
                            [lbs2kg(2100), inches2m(93)],
                            [lbs2kg(1200), inches2m(93)] ],
        info: [
            {title: "Max crosswind", value: "17 kt"},
            {title: "Vs", value: "55 kt"},
            {title: "Vso", value: "49 kt"},
            {title: "Vx", value: "64 kt"},
            {title: "Vy", value: "76 kt"},
            {title: "Final appr speed", value: "66 kt"},
            {title: "Fuel", value: "91/96 UL or 100LL"},
            {title: "Fuelflow", value: "40 liters/h"},
        ],
    },
    {
        registration: "SE-LRL",
        maxTow: lbs2kg(2550),
        maxFuel: usgal2liter(50),
        maxBaggage: lbs2kg(200),
        bew: 791.08,
        bewArm: 2.2621,
        frontSeatArm: inches2m(80.5),
        backSeatArm: inches2m(118.1),
        fuelArm: inches2m(95.0),
        baggageArm: inches2m(142.8),
        cogLimits: [ [lbs2kg(1200), inches2m(82)],
                     [lbs2kg(2050), inches2m(82)],
                     [lbs2kg(2550), inches2m(88.5)],
                     [lbs2kg(2550), inches2m(93)],
                     [lbs2kg(1200), inches2m(93)] ],
        cogLimitsUtility: [ [lbs2kg(1200), inches2m(82)],
                            [lbs2kg(2050), inches2m(82)],
                            [lbs2kg(2100), inches2m(82.7)],
                            [lbs2kg(2100), inches2m(93)],
                            [lbs2kg(1200), inches2m(93)] ],
        info: [
            {title: "Max crosswind", value: "17 kt"},
            {title: "Vs", value: "50 kt"},
            {title: "Vso", value: "45 kt"},
            {title: "Vx", value: "64 kt"},
            {title: "Vy", value: "76 kt"},
            {title: "Final appr speed", value: "66 kt"},
            {title: "Fuel", value: "91/96 UL or 100LL"},
            {title: "Fuelflow", value: "40 liters/h"},
        ],
    },
    {
        registration: "SE-MMZ",
        maxTow: lbs2kg(2550),
        maxFuel: usgal2liter(50),
        maxBaggage: lbs2kg(200),
        bew: 784.0,
        bewArm: 2.231,
        frontSeatArm: inches2m(80.5),
        backSeatArm: inches2m(118.1),
        fuelArm: inches2m(95.0),
        baggageArm: inches2m(142.8),
        cogLimits: [ [lbs2kg(1200), inches2m(82)],
                     [lbs2kg(2050), inches2m(82)],
                     [lbs2kg(2550), inches2m(88.5)],
                     [lbs2kg(2550), inches2m(93)],
                     [lbs2kg(1200), inches2m(93)] ],
        cogLimitsUtility: [ [lbs2kg(1200), inches2m(82)],
                            [lbs2kg(2050), inches2m(82)],
                            [lbs2kg(2100), inches2m(82.7)],
                            [lbs2kg(2100), inches2m(93)],
                            [lbs2kg(1200), inches2m(93)] ],
        info: [
            {title: "Max crosswind", value: "17 kt"},
            {title: "Vs", value: "50 kt"},
            {title: "Vso", value: "45 kt"},
            {title: "Vx", value: "64 kt"},
            {title: "Vy", value: "76 kt"},
            {title: "Final appr speed", value: "66 kt"},
            {title: "Fuel", value: "91/96 UL or 100LL"},
            {title: "Fuelflow", value: "40 liters/h"},
        ],
    },
    {
        registration: "SE-KMH",
        maxTow: 1055,
        maxFuel: 182,
        maxBaggage: lbs2kg(50),
        bew: 667.3,
        bewArm: 2.139,
        frontSeatArm: inches2m(80.5),
        backSeatArm: inches2m(118.1),
        fuelArm: inches2m(95.0),
        baggageArm: inches2m(142.8),
        cogLimits: [ [lbs2kg(1200), inches2m(83)],
                     [lbs2kg(1950), inches2m(83)],
                     [lbs2kg(2325), inches2m(87)],
                     [lbs2kg(2325), inches2m(93)],
                     [lbs2kg(1200), inches2m(93)] ],
        cogLimitsUtility: [ [lbs2kg(1200), inches2m(83)],
                            [lbs2kg(1950), inches2m(83)],
                            [lbs2kg(2000), inches2m(83.7)],
                            [lbs2kg(2000), inches2m(93)],
                            [lbs2kg(1200), inches2m(93)] ],
        info: [
            {title: "Max crosswind", value: "17 kt"},
            {title: "Vs", value: "50 kt"},
            {title: "Vso", value: "44 kt"},
            {title: "Vx", value: "63 kt"},
            {title: "Vy", value: "79 kt"},
            {title: "Final appr speed", value: "63 kt"},
            {title: "Fuel", value: "91/96 UL or 100LL"},
            {title: "Fuelflow", value: "40 liters/h"},
        ],
    },
    {
        registration: "SE-MEB",
        maxTow: 800,
        maxFuel: 93,
        maxBaggage: 20,
        bew: 556.2,
        bewArm: 0.2108,
        frontSeatArm: 0.143,
        fuelArm: 0.824,
        baggageArm: 0.824,
        cogLimits: [ [lbs2kg(1200), 110/lbs2kg(1200)],
                     [800, 165/800],
                     [800, 245/800],
                     [750, 238/750],
                     [lbs2kg(1200), 173/lbs2kg(1200)], ],
        info: [
            {title: "Max crosswind", value: "20 kt"},
            {title: "Vs", value: "44 kt"},
            {title: "Vso", value: "36 kt"},
            {title: "Vx", value: "58 kt"},
            {title: "Vy", value: "75 kt"},
            {title: "Final appr speed", value: "55 kt"},
            {title: "Fuel", value: "100LL"},
            {title: "Fuelflow", value: "22 liters/h"},
        ]
    },
]
