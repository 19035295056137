import {Kg, Meter, Liter} from './Types';

export const lbs2kg = (lbs: number): Kg => {
    return lbs * 0.4535924;
}

export const inches2m = (inches: number): Meter => {
    return inches * 0.0254;
}

export const liter2kg = (liters: number): Liter => {
    return liters * 0.72;
}

export const usgal2liter = (usgal: number): Liter => {
    return usgal * 3.7854;
}
